@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif !important;
	margin: 0;
	overflow-y: hidden;
}

.fade.modal.show {
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px)
}
